import GC from '@mescius/spread-sheets';
import '@mescius/spread-sheets-print';
import '@mescius/spread-sheets-io';
import '@mescius/spread-sheets-shapes';
import '@mescius/spread-sheets-charts';
import '@mescius/spread-sheets-slicers';
import '@mescius/spread-sheets-pivot-addon';
import '@mescius/spread-sheets-reportsheet-addon';
import "@mescius/spread-sheets-tablesheet";
import "@mescius/spread-sheets-ganttsheet";
import { SpreadSheets, Worksheet } from '@mescius/spread-sheets-react';
import '@mescius/spread-sheets/styles/gc.spread.sheets.excel2013white.css';
import { Box, Button, Flex, useBoolean } from '@chakra-ui/react';
import { useAxCalcStore } from 'src/store/axCalc.store';
import { BiDownload, BiSave } from 'react-icons/bi';
import AddFileToCalcModal from '../common/modals/AddFileToCalcModal';

GC.Spread.Common.CultureManager.culture('ja-jp');

// @ts-ignore
window.GC = GC;

GC.Spread.Sheets.LicenseKey = "axeleratum.com|orkestadoc.axeleratum.com|orkestadocqa.axeleratum.com|orkestadoc-mediaqa.axeleratum.com,544153217483177#B1ZdlQJRUTP3GTvF6YYh6KHZVczVURoxWTWVDeN3EUIJUV5N5b4hUVpBDUKhHOhNTQhFnQIVkU62SRywURBZ5Uxo5awtWQSdmbhlHOFpkVTFEWKt4QzMXeilTcsR5ZZRUMYZkRTBncYdnYENjQFBTaipXe0tmMSdVZ4Vmbp5UWFpneKNlQDhVWJdTdZhEOPp6VnpUZ0RXNHNDODpmaEhWeiRnVQ3GMHNGT7ITSR9GeKRzaYZ5QnVGM6AnYWNzdhJDap9UWatSO8l7NvY4SzR4cLpkNDdTcsJnWLhWd5BHbzVWWZJ7L6QlNylkMlNleJV6KhFmarJzYUd7SBJGaPFjZ89mWrRDRLJzTwwkczVTQzoWNxEkMkhnY5kmMPNESiojITJCLicTRyQzN7gjMiojIIJCL4YzM8IDO4kTN0IicfJye#4Xfd5nIVF4SRJiOiMkIsIyNx8idgMlSgQWYlJHcTJiOi8kI1tlOiQmcQJCLiYDM8ITOwAyMykDM4IDMyIiOiQncDJCLi46bj9Sb5RXYyVGblhXYuEWchlGZl5WLj3GZhR7cltmcvxSbvNmLtVHdhJXZsVGeh9SYxN6bkFGdzV6ay3GLt36Yu4Wd4FmclxWZ8FmLj3GZhR7cltmcvxSbvNmLtVHdhJXZsVGehJiOiMXbEJCLiwGbpRWYSBicp5WakFGbWJiOiEmTDJCLiczNxMDO4cTMyMTNxQDN5IiOiQWSiwSfdtlOicGbmJCLlNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TP7pWa0l5aZVka9o4LYZzMXFGZZJHcVlUQ6EEOINkVEBVT6VDRRZUNmdXcDRXdwlEeCZ5b6cnNJYVV";        // Enter a valid license key.      

const BiddingAxCalc = () => {

  const [showAddStage, setShowAddStage] = useBoolean(false);
  const { setSpread, save } = useAxCalcStore();

  let hostStyle = {
    width: '100%',
    height: '600px',
    border: '1px solid darkgray'
  }


  function initSpread(spread: GC.Spread.Sheets.Workbook) {
    setSpread(spread);
    // Init Status Bar
    const statusBar = new GC.Spread.Sheets.StatusBar.StatusBar(document.getElementById('statusBar')!);
    statusBar.bind(spread);
  }

  return (
    <Box mt={2}>
      <Flex justifyContent={'flex-end'} gap={3} mb={3}>
        <Button
          rounded={"full"}
          size={"md"}
          fontWeight={"medium"}
          px={"2rem"}
          onClick={() => save()} leftIcon={<BiDownload />}>
          Descargar
        </Button>
        <Button
          rounded={"full"}
          size={"md"}
          fontWeight={"medium"}
          px={"2rem"}
          onClick={setShowAddStage.on} leftIcon={<BiSave />} >
          Cargar Documento
        </Button>
      </Flex>
      <Box>
        <SpreadSheets workbookInitialized={spread => initSpread(spread)} hostStyle={hostStyle}>
          <Worksheet />
        </SpreadSheets>
      </Box>
      <div id="statusBar"></div>
      {showAddStage && (
        <AddFileToCalcModal onCancel={setShowAddStage.off} isOpen={true} />
      )}
    </Box>
  );
}

export default BiddingAxCalc;